import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { IOrderForEdit, IOrder } from '../../interfaces/order';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { getOrders, deleteOrder, editOrder, getOrderById } from '../../crud/orders.crud';
import { IServerResponse } from '../../interfaces/server';
import { getResponseMessage } from '../../utils/utils';

const FETCH_REQUEST = 'orders/FETCH_REQUEST';
const FETCH_SUCCESS = 'orders/FETCH_SUCCESS';
const FETCH_FAIL = 'orders/FETCH_FAIL';

const FETCH_BY_ID_REQUEST = 'orders/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'orders/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'orders/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'orders/CLEAR_EDIT';
const EDIT_REQUEST = 'orders/EDIT_REQUEST';
const EDIT_SUCCESS = 'orders/EDIT_SUCCESS';
const EDIT_FAIL = 'orders/EDIT_FAIL';

const EDIT_STATUS_REQUEST = 'orders/EDIT_STATUS_REQUEST';
const EDIT_STATUS_SUCCESS = 'orders/EDIT_STATUS_SUCCESS';
const EDIT_STATUS_FAIL = 'orders/EDIT_STATUS_FAIL';

const DELETE_REQUEST = 'orders/DELETE_REQUEST';
const DELETE_SUCCESS = 'orders/DELETE_SUCCESS';
const DELETE_FAIL = 'orders/DELETE_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  orders: IOrder[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  order: IOrder | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  orders: undefined,
  loading: false,
  success: false,
  error: null,

  order: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'models', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          orders: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          orders: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          order: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          order: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          order: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
          delError: null,
        };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case DELETE_FAIL: {
        return { ...state, delError: action.payload };
      }

      case EDIT_STATUS_REQUEST: {
        return { ...state, loading: true };
      }
      case EDIT_STATUS_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number; statuses: string }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IOrder[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IOrder>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: { id: number; data: IOrderForEdit }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  editStatusRequest: (payload: {
    id: number;
    data: IOrderForEdit;
    statuses: string;
    page: number;
    perPage: number;
  }) => createAction(EDIT_STATUS_REQUEST, payload),
  editStatusSuccess: () => createAction(EDIT_STATUS_SUCCESS),
  editStatuFail: (payload: string) => createAction(EDIT_STATUS_FAIL, payload),

  delRequest: (payload: { id: number; statuses: string; page: number; perPage: number }) =>
    createAction(DELETE_REQUEST, payload),
  delSuccess: () => createAction(DELETE_SUCCESS),
  delFail: (payload: string) => createAction(DELETE_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number; statuses: string } }) {
  try {
    const { data }: { data: IServerResponse<IOrder[]> } = yield call(() =>
      getOrders(payload.statuses, payload.page, payload.perPage)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* delSaga({
  payload,
}: {
  payload: { id: number; statuses: string; page: number; perPage: number };
}) {
  try {
    yield call(() => deleteOrder(payload.id));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        statuses: payload.statuses,
      })
    );
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

function* editStatusSaga({
  payload,
}: {
  payload: { id: number; statuses: string; page: number; perPage: number; data: IOrderForEdit };
}) {
  try {
    yield call(() => editOrder(payload.id, payload.data));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        statuses: payload.statuses,
      })
    );
  } catch (e) {
    yield put(actions.editStatuFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IOrder> } = yield call(() => getOrderById(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload: { id, data } }: { payload: { id: number; data: IOrderForEdit } }) {
  try {
    yield call(() => editOrder(id, data));

    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DELETE_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.editStatusRequest>>(
    EDIT_STATUS_REQUEST,
    editStatusSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
}
