import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { actions as profileActions } from '../../store/ducks/profile.duck';
import { actions as authActions } from '../../store/ducks/auth.duck';
import Preloader from '../../components/ui/Preloader/Preloader';
import { IAppState } from '../../store/rootDuck';
import { useShowErrors } from '../../hooks/useShowErrors';

const RedirectFromApp: React.FC<RouteComponentProps<{ token: string }>> = ({
  match: {
    params: { token },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { me, meError, meSuccess, authToken } = useSelector(
    ({ profile: { me, error, success }, auth: { authToken } }: IAppState) => ({
      me,
      meError: error,
      meSuccess: success,
      authToken,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(authActions.setToken(token));
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(profileActions.fetchRequest());
  }, [dispatch, authToken]);

  useEffect(() => {
    if (meSuccess && me) {
      dispatch(authActions.setUser(me));
    }
  }, [meSuccess, me]);

  useShowErrors([meError]);
  useEffect(() => {
    meError && history.push('/auth/main');
  }, [meError]);

  return <Preloader />;
};

export default RedirectFromApp;
