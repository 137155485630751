import { Reducer } from 'redux';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IDoc } from '../../interfaces/docs';

const FETCH_SUCCESS = 'agreement/FETCH_SUCCESS';

export interface IInitialState {
  agreement: IDoc | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;
}

const initialState: IInitialState = {
  agreement: undefined,
  loading: false,
  success: false,
  error: null,
};

export const reducer: Reducer<IInitialState, TAppActions> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      return { ...state, agreement: action.payload.data, loading: false, success: true };
    }

    default:
      return state;
  }
};

export const actions = {
  fetchSuccess: (payload: IServerResponse<IDoc>) => createAction(FETCH_SUCCESS, payload),
};

export type TActions = ActionsUnion<typeof actions>;
