import axios from 'axios';
import { IAdvantageItemToRequest } from '../pages/home/properties/advantage/interfaces';
import { getAdvantageUrl, editAdvantageUrl } from '../constants';

export function getAdvantage() {
  return axios.get(getAdvantageUrl());
}

export function editAdvantage(data: IAdvantageItemToRequest) {
  return axios.post(editAdvantageUrl(), data);
}
