import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TAppActions } from '../rootDuck';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { Reducer } from 'redux';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse, IPaginationProps } from '../../interfaces/server';
import { getBrands, editBrand, getBrandById, createBrand } from '../../crud/brand.crud';
import { IBrandItem } from '../../pages/home/catalog/brands/interfaces';
import { IRouterParams } from '../../interfaces/router';
import { getResponseMessage } from '../../utils/utils';

const ADD_BRAND_REQUEST = 'ADD_BRAND_REQUEST';

const FETCH_BRANDS_REQUEST = 'FETCH_BRANDS_REQUEST';
const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
const FETCH_BRANDS_FAIL = 'FETCH_BRANDS_FAIL';
const CHANGE_FETCH_LIMIT = 'CHANGE_FETCH_LIMIT';

const FETCH_BRAND_BY_ID_REQUEST = 'FETCH_BRAND_BY_ID_REQUEST';
const FETCH_BRAND_BY_ID_SUCCESS = 'FETCH_BRAND_BY_ID_SUCCESS';
const FETCH_BRAND_BY_ID_FAIL = 'FETCH_BRAND_BY_ID_FAIL';

const EDIT_BRAND_REQUEST = 'EDIT_BRAND_REQUEST';
const EDIT_BRAND_SUCCESS = 'EDIT_BRAND_SUCCESS';
const EDIT_BRAND_FAIL = 'EDIT_BRAND_FAIL';

const CLEAR_REQUEST = 'CLEAR_REQUEST';

export interface IInitialState {
  brand: IBrandItem | null;
  brands: IBrandItem[];
  loading: boolean;
  loadingEdit: boolean;
  editSuccess: boolean;
  editFail: boolean;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const initialState = {
  brand: null,
  brands: [],
  loading: false,
  loadingEdit: false,
  editSuccess: false,
  editFail: false,
  page: 1,
  per_page: 20,
  total: 500,
  total_pages: 0,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'brands', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BRANDS_REQUEST: {
        return { ...state, loading: true, brands: [], brand: null };
      }

      case CHANGE_FETCH_LIMIT: {
        return { ...state, per_page: action.payload };
      }

      case FETCH_BRANDS_SUCCESS: {
        const { data, page, per_page, total, total_pages } = action.payload;
        return {
          ...state,
          loading: false,
          brands: data,
          page,
          per_page,
          total,
          total_pages,
        };
      }

      case FETCH_BRANDS_FAIL: {
        return { ...state, loading: false };
      }

      case CLEAR_REQUEST: {
        return { ...state, loadingEdit: false, editFail: false, editSuccess: false };
      }

      case ADD_BRAND_REQUEST:
      case EDIT_BRAND_REQUEST: {
        return { ...state, loadingEdit: true, editSuccess: false, editFail: false };
      }

      case EDIT_BRAND_FAIL: {
        return { ...state, loadingEdit: false, editFail: true };
      }

      case EDIT_BRAND_SUCCESS: {
        const brands = [...state.brands].map((brand: IBrandItem) => {
          if (brand.id === action.payload.id) {
            brand = action.payload;
          }
          return brand;
        });

        return {
          ...state,
          brands,
          loadingEdit: false,
          editSuccess: true,
        };
      }

      case FETCH_BRAND_BY_ID_REQUEST: {
        return { ...state, brand: null };
      }

      case FETCH_BRAND_BY_ID_SUCCESS: {
        return {
          ...state,
          brand: action.payload.data,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchBrands: (payload: IPaginationProps & IRouterParams) =>
    createAction(FETCH_BRANDS_REQUEST, payload),
  fetchBrandsSuccess: (payload: IServerResponse<IBrandItem[]>) =>
    createAction(FETCH_BRANDS_SUCCESS, payload),
  fetchBrandsFail: (e: any) => createAction(FETCH_BRANDS_FAIL, e),
  clearRequest: () => createAction(CLEAR_REQUEST),
  changeFetchLimit: (payload: number) => createAction(CHANGE_FETCH_LIMIT, payload),

  fetchBrandById: (payload: number) => createAction(FETCH_BRAND_BY_ID_REQUEST, payload),
  fetchBrandByIdSuccess: (payload: IServerResponse<IBrandItem>) =>
    createAction(FETCH_BRAND_BY_ID_SUCCESS, payload),
  fetchBrandByIdFail: (e: any) => createAction(FETCH_BRAND_BY_ID_FAIL, e),

  addBrand: (payload: { brandData: IBrandItem }) => createAction(ADD_BRAND_REQUEST, payload),
  editBrand: (payload: { brandId: number; brandData: IBrandItem }) =>
    createAction(EDIT_BRAND_REQUEST, payload),
  editBrandSuccess: (payload: IBrandItem) => createAction(EDIT_BRAND_SUCCESS, payload),
  editBrandFail: (e: any) => createAction(EDIT_BRAND_FAIL, e),
};

export type TActions = ActionsUnion<typeof actions>;

function* fethBrandsSaga({ payload }: { payload: IPaginationProps & IRouterParams }) {
  try {
    const { data }: { data: IServerResponse<IBrandItem[]> } = yield call(() => getBrands(payload));
    yield put(actions.fetchBrandsSuccess(data));
  } catch (e) {
    yield put(actions.fetchBrandsFail(getResponseMessage(e)));
  }
}

function* fethBrandByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IBrandItem> } = yield call(() => getBrandById(payload));
    yield put(actions.fetchBrandByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchBrandByIdFail(getResponseMessage(e)));
  }
}

function* addBrandSaga({ payload: { brandData } }: { payload: { brandData: IBrandItem } }) {
  try {
    const { data }: { data: IServerResponse<IBrandItem> } = yield call(() =>
      createBrand(brandData)
    );

    if (data.status === 200) {
      yield put(actions.editBrandSuccess(data.data));
    } else {
      yield put(actions.editBrandFail(data.errors));
    }
  } catch (e) {
    yield put(actions.editBrandFail(getResponseMessage(e)));
  }
}

function* editBrandSaga({
  payload: { brandId, brandData },
}: {
  payload: { brandId: number; brandData: IBrandItem };
}) {
  try {
    const { data }: { data: IServerResponse<IBrandItem> } = yield call(() =>
      editBrand(brandId, brandData)
    );

    if (data.status === 200) {
      yield put(actions.editBrandSuccess(data.data));
    } else {
      yield put(actions.editBrandFail(data.errors));
    }
  } catch (e) {
    yield put(actions.editBrandFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchBrandById>>(
    FETCH_BRAND_BY_ID_REQUEST,
    fethBrandByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchBrands>>(FETCH_BRANDS_REQUEST, fethBrandsSaga);
  yield takeLatest<ReturnType<typeof actions.addBrand>>(ADD_BRAND_REQUEST, addBrandSaga);
  yield takeLatest<ReturnType<typeof actions.editBrand>>(EDIT_BRAND_REQUEST, editBrandSaga);
}
