import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import ButtonWithLoader from '../../../components/ui/Buttons/ButtonWithLoader';

interface IProps {
  formik: any;
  regLoading: boolean;
  openUserAgreement: () => void;
}

const PartnerRegForm: React.FC<IProps & WrappedComponentProps> = ({
  intl,
  formik,
  openUserAgreement,
  regLoading,
}) => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <div className="form-group mb-0">
        <TextField
          label={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
          margin="normal"
          className="kt-width-full"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          helperText={touched.email && errors.email}
          error={Boolean(touched.email && errors.email)}
        />
      </div>

      <div className="form-group mb-0">
        <FormControlLabel
          label={
            <>
              {intl.formatMessage({ id: 'AUTH.REGISTER.AGREE_TERM' })}{' '}
              <div className="kt-link" onClick={openUserAgreement}>
                {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
              </div>
            </>
          }
          control={
            <Checkbox
              color="primary"
              name="acceptTerms"
              onBlur={handleBlur}
              onChange={handleChange}
              checked={values.acceptTerms}
            />
          }
        />
      </div>

      <div className="kt-login__actions">
        <Link to="/auth">
          <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
            {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
          </button>
        </Link>

        <ButtonWithLoader
          disabled={regLoading || !values.acceptTerms}
          onPress={handleSubmit}
          loading={regLoading}
        >
          {intl.formatMessage({ id: 'AUTH.BUTTON.REGISTER' })}
        </ButtonWithLoader>
      </div>
    </form>
  );
};

export default injectIntl(PartnerRegForm);
