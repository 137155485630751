import axios from 'axios';
import {
  getModificationsUrl,
  getModificationByIdUrl,
  addModificationUrl,
  editModificationUrl,
  uploadPhotoUrl,
  delPhotoUrl,
  setMainPhotoUrl,
} from '../constants';
import { IModificationItemToRequest } from '../pages/home/catalog/modifications/interfaces';

export function getModifications(page: number, perPage: number, bodyId: number) {
  return axios.get(`${getModificationsUrl(bodyId)}?page=${page}&per_page=${perPage}`);
}

export function getModificationById(id: number) {
  return axios.get(getModificationByIdUrl(id));
}

export function addModification(bodyId: number, data: IModificationItemToRequest) {
  return axios.post(addModificationUrl(bodyId), data);
}

export function editModification(id: number, data: IModificationItemToRequest) {
  return axios.put(editModificationUrl(id), data);
}

export function uploadPhoto(modificationId: number, file: FormData) {
  return axios.post(uploadPhotoUrl(modificationId), file, {
    headers: { 'content-type': 'multipart/form-data' },
  });
}

export function delPhoto(id: number) {
  return axios.delete(delPhotoUrl(id));
}

export function setMainPhoto(id: number) {
  return axios.post(setMainPhotoUrl(id));
}
