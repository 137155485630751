import axios from 'axios';
import {
  getEquipmentsUrl,
  getEquipmentByIdUrl,
  addEquipmentUrl,
  editEquipmentUrl,
} from '../constants';
import { IEquipmentItemToRequest } from '../pages/home/catalog/equipments/interfaces';

export function getEquipments(page: number, perPage: number, modificationId: number) {
  return axios.get(`${getEquipmentsUrl(modificationId)}?page=${page}&per_page=${perPage}`);
}

export function getEquipmentById(id: number) {
  return axios.get(getEquipmentByIdUrl(id));
}

export function addEquipment(modificationId: number, data: IEquipmentItemToRequest) {
  return axios.post(addEquipmentUrl(modificationId), data);
}

export function editEquipment(id: number, data: IEquipmentItemToRequest) {
  return axios.put(editEquipmentUrl(id), data);
}
