import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { ActionWithPayload } from '../../../utils/action-helper';

// <--------------- Formik --------------->

export const useNewPassFormik = (
  code: string,
  newPasswordRequest: (payload: {
    password: string;
    password2: string;
    code: string;
  }) => ActionWithPayload<
    'auth/NEW_PASSWORD_REQUEST',
    {
      password: string;
      password2: string;
      code: string;
    }
  >
) => {
  const intl = useIntl();
  return useFormik({
    initialValues: {
      password: '',
      password2: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(intl.formatMessage({ id: 'AUTH.PASSWORD.INPUT' })),
      password2: Yup.string()
        .required(intl.formatMessage({ id: 'AUTH.PASSWORD.REPEAT' }))
        .oneOf([Yup.ref('password'), ''], intl.formatMessage({ id: 'AUTH.PASSWORD.EQUAL' })),
    }),
    onSubmit: (values) => {
      newPasswordRequest({ password: values.password, password2: values.password2, code });
    },
  });
};
