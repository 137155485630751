import axios from 'axios';
import { getModelsUrl, addModelUrl, editModelUrl, getModelByIdUrl } from '../constants';
import { IModelItemToRequest } from '../pages/home/catalog/models/interfaces';

export function getModels(page: number, perPage: number, brandId: number) {
  return axios.get(`${getModelsUrl(brandId)}?page=${page}&per_page=${perPage}`);
}

export function getModelById(modelId: number) {
  return axios.get(getModelByIdUrl(modelId));
}

export function addModel(brandId: number, data: IModelItemToRequest) {
  return axios.post(addModelUrl(brandId), data);
}

export function editModel(modelId: number, data: IModelItemToRequest) {
  return axios.put(editModelUrl(modelId), data);
}
