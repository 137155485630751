import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { toAbsoluteUrl } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordEmail from './ForgotPasswordEmail';
import NewPassword from './NewPassword';
import EmailSentPage from './EmailSentPage';
import RedirectFromApp from './RedirectFromApp';

const ModelsPage: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  {/* <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-4.png')} /> */}
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">
                    {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' })}
                  </h3>
                  <h4 className="kt-login__subtitle">
                    {intl.formatMessage({ id: 'AUTH.WELCOME.SUBTITLE' })}
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  {/* <div className="kt-login__copyright">
                    <div className="kt-login__menu">
                      <a
                        href={'https://start-mobile.net'}
                        className="kt-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.formatMessage({ id: 'AUTH.CREATOR.COMPANY' })}
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route path="/auth/email-sent/registration" component={EmailSentPage} />
                <Route path="/auth/forgot-password" component={ForgotPassword} />
                <Route path="/auth/forgot-password-email" component={ForgotPasswordEmail} />
                <Route path="/auth/change_password/:code" component={NewPassword} />
                <Route path="/auth/redirect-from-app/:token" component={RedirectFromApp} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelsPage;
