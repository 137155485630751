import React from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ButtonWithLoader } from '../../components/ui/Buttons';
import { useNewPassFormik } from './hooks/useNewPassFormik';
import { useFetchNewPass } from './hooks/useFetchNewPass';

const NewPassword: React.FC<RouteComponentProps<{ code: string }>> = ({
  match: {
    params: { code },
  },
}) => {
  const [newPasswordRequest, newPasswordLoading] = useFetchNewPass();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useNewPassFormik(
    code,
    newPasswordRequest
  );
  const history = useHistory();
  const intl = useIntl();

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>Новый пароль</h3>
        </div>

        <form onSubmit={handleSubmit} className="kt-form">
          <div role="alert" className="alert alert-info">
            <div className="alert-text">
              {intl.formatMessage({ id: 'AUTH.PASSWORD.INPUT_TITLE' })}
            </div>
          </div>
          <div className="form-group">
            <TextField
              autoComplete="off"
              type="password"
              margin="normal"
              label="Новый пароль"
              className="kt-width-full"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
            />
          </div>

          <div className="form-group">
            <TextField
              autoComplete="off"
              type="password"
              margin="normal"
              label="Новый пароль еще раз"
              className="kt-width-full"
              name="password2"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password2}
              helperText={touched.password2 && errors.password2}
              error={Boolean(touched.password2 && errors.password2)}
            />
          </div>

          <div className="kt-login__actions">
            <Grid container direction="row" justify="space-between" alignItems="stretch">
              <Button onClick={() => history.push('/auth')} variant="outlined" color="primary">
                {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
              </Button>

              <ButtonWithLoader disabled={newPasswordLoading} loading={newPasswordLoading}>
                {intl.formatMessage({ id: 'AUTH.PASSWORD.BUTTON' })}
              </ButtonWithLoader>
            </Grid>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
