import { useEffect, useState, useCallback } from 'react';

export const useTimer: any = () => {
  const [time, setTime] = useState(100);
  const [start, setStart] = useState(false);
  let interval: NodeJS.Timeout;
  useEffect(() => {
    if (start) {
      interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);
      time === 0 && clearInterval(interval);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  });

  const startTimer = useCallback(() => {
    setStart(true);
    setTime(100);
  }, []);

  const resetTimer = useCallback(() => {
    setStart(false);
    setTime(100);
  }, []);

  return [time, startTimer, resetTimer];
};
