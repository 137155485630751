import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FormControlLabel, RadioGroup, Radio, FormLabel } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import TermDialog from './components/TermDialog';
import { useFetchReg } from './hooks/useFetchReg';
import { useRegFormik } from './hooks/useRegFormik';
import PartnerRegForm from './components/PartnerRegForm';
import LoginByPhone from './components/LoginByPhone';
import { IAppState } from '../../store/rootDuck';
import { useFetchCheckSms } from './hooks/useFetchCheckSms';
import { useFetchRegPhone } from './hooks/useFetchRegPhone';
import { useTimer } from './hooks/useTimer';

const Registration: React.FC<WrappedComponentProps> = ({ intl }) => {
  const [isAgreementOpen, setOpenUserAgreement] = useState(false);
  const checkPhoneNumbers = useSelector(
    ({ auth: { checkPhoneNumbers } }: IAppState) => checkPhoneNumbers,
    shallowEqual
  );
  const [register, regLoading] = useFetchReg();
  const [loginPhone, loginPhoneLoading] = useFetchRegPhone();
  const [checkSms, checkLoading] = useFetchCheckSms();
  const [time, startTimer, resetTimer] = useTimer();
  const formik = useRegFormik(register, loginPhone, checkPhoneNumbers, checkSms);
  const { values, handleChange } = formik;

  useEffect(() => {
    return () => {
      resetTimer();
    };
  }, [resetTimer]);

  useEffect(() => {
    !checkPhoneNumbers && resetTimer();
    checkPhoneNumbers && startTimer();
  }, [checkPhoneNumbers]);

  const resending = useCallback(() => {
    loginPhone({ phone: values.phone });
    startTimer();
  }, [loginPhone, startTimer, values.phone]);

  return (
    <>
      <TermDialog isOpen={isAgreementOpen} handleClose={() => setOpenUserAgreement(false)} />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.REGISTER.TITLE" />
            </h3>
          </div>

          {!checkPhoneNumbers && (
            <RadioGroup name="role" value={values.role} onChange={handleChange}>
              <FormLabel component="legend">
                {intl.formatMessage({ id: 'AUTH.REGISTER.ROLE_TITLE' })}
              </FormLabel>
              <FormControlLabel
                value="ROLE_BUYER"
                control={<Radio />}
                label={intl.formatMessage({ id: 'USER.ROLES.BUYER' })}
              />
              <FormControlLabel
                value="ROLE_VENDOR"
                control={<Radio />}
                label={intl.formatMessage({ id: 'USER.ROLES.VENDOR' })}
              />
            </RadioGroup>
          )}

          <div style={{ display: values.role === 'ROLE_VENDOR' ? 'block' : 'none' }}>
            <PartnerRegForm
              regLoading={regLoading}
              formik={formik}
              openUserAgreement={() => setOpenUserAgreement(true)}
            />
          </div>
          <div style={{ display: values.role === 'ROLE_BUYER' ? 'block' : 'none' }}>
            <LoginByPhone
              formik={formik}
              openUserAgreement={() => setOpenUserAgreement(true)}
              checkPhoneNumbers={checkPhoneNumbers}
              time={time}
              resending={resending}
              loginPhoneLoading={loginPhoneLoading}
              checkLoading={checkLoading}
              isReg
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(Registration);
