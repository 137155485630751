import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  IEquipmentItem,
  IEquipmentItemToRequest,
} from '../../pages/home/catalog/equipments/interfaces';
import {
  getEquipments,
  getEquipmentById,
  addEquipment,
  editEquipment,
} from '../../crud/equipments.crud';
import arrayOfOptionsIds from '../../pages/home/catalog/equipments/utils/arrayOfOptionsIds';
import {getResponseMessage} from '../../utils/utils';

const SET_NEW_EQUIPMENTS_IDS = 'equipments/SET_NEW_EQUIPMENTS_IDS';

const FETCH_REQUEST = 'equipments/FETCH_REQUEST';
const FETCH_SUCCESS = 'equipments/FETCH_SUCCESS';
const FETCH_FAIL = 'equipments/FETCH_FAIL';

const CLEAR_FETCH_BY_ID = 'equipments/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'equipments/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'equipments/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'equipments/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'equipments/CLEAR_EDIT';
const ADD_REQUEST = 'equipments/ADD_REQUEST';
const EDIT_REQUEST = 'equipments/EDIT_REQUEST';
const EDIT_SUCCESS = 'equipments/EDIT_SUCCESS';
const EDIT_FAIL = 'equipments/EDIT_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  equipments: IEquipmentItem[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  equipment_ids: number[];
  equipment: IEquipmentItem | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  equipments: undefined,
  loading: false,
  success: false,
  error: null,

  equipment_ids: [],
  equipment: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'equipments', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_NEW_EQUIPMENTS_IDS: {
        return {
          ...state,
          equipment_ids: action.payload,
        };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          equipments: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          equipments: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

      case CLEAR_EDIT: {
        return { ...state, editLoading: false, editError: null, editSuccess: false };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return {
          ...state,
          equipment_ids: [],
          equipment: undefined,
          byIdLoading: false,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          equipment_ids: [],
          equipment: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          equipment_ids: arrayOfOptionsIds(action.payload.data.equipments_by_groups),
          equipment: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return {
          ...state,
          byIdLoading: false,
          byIdError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setNewEquipmentIds: (payload: number[]) => createAction(SET_NEW_EQUIPMENTS_IDS, payload),

  fetchRequest: (payload: { page: number; perPage: number; id: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IEquipmentItem[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IEquipmentItem>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { modificationId: number; data: IEquipmentItemToRequest }) =>
    createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IEquipmentItemToRequest }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: any) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number; id: number } }) {
  try {
    const { data }: { data: IServerResponse<IEquipmentItem[]> } = yield call(() =>
      getEquipments(payload.page, payload.perPage, payload.id)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IEquipmentItem> } = yield call(() =>
      getEquipmentById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* addSaga({
  payload,
}: {
  payload: { modificationId: number; data: IEquipmentItemToRequest };
}) {
  try {
    const data = yield call(() => addEquipment(payload.modificationId, payload.data));
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IEquipmentItemToRequest } }) {
  try {
    const data = yield call(() => editEquipment(payload.id, payload.data));
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
}
