import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../store/rootDuck';

const ForgotPasswordEmail: React.FC = () => {
  const email = useSelector(({ auth: { email } }: IAppState) => email, shallowEqual);
  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>Вам письмо!</h3>
        </div>
        <div className="form-group">{email}</div>
      </div>
    </div>
  );
};

export default ForgotPasswordEmail;
