import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IAppState } from '../../../store/rootDuck';
import { useFetchAgreement } from '../hooks/useFetchAgreement';

const agreementStyle = {
  color: 'black',
};

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const TermDialog: React.FC<IProps & WrappedComponentProps> = ({ isOpen, handleClose, intl }) => {
  const [fetchAgreement] = useFetchAgreement();
  const agreement = useSelector(
    ({ agreement: { agreement } }: IAppState) => agreement,
    shallowEqual
  );
  useEffect(() => {
    fetchAgreement();
  }, [fetchAgreement]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
      </DialogTitle>
      <DialogContent>
        {agreement ? (
          <div dangerouslySetInnerHTML={{ __html: agreement.text }} style={agreementStyle} />
        ) : (
          <>{intl.formatMessage({ id: 'AUTH.TERM.LOADING' })}</>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default injectIntl(TermDialog);
