import React from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ButtonWithLoader } from '../../components/ui/Buttons';
import { useForgotPassFormik } from './hooks/useForgotPassFormik';
import { useFetchRecoveryPass } from './hooks/useFetchRecoveryPass';

const ForgotPassword: React.FC<WrappedComponentProps> = ({ intl }) => {
  const [recoveryPasswordRequest, recoveryPasswordLoading] = useFetchRecoveryPass();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useForgotPassFormik(
    recoveryPasswordRequest
  );
  const history = useHistory();

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
        </div>
        <form onSubmit={handleSubmit} className="kt-form">
          <div role="alert" className="alert alert-info">
            <div className="alert-text">
              {`${intl.formatMessage({
                id: 'AUTH.FORGOT.INPUT_TEXT1',
              })} e-mail ${intl.formatMessage({ id: 'AUTH.FORGOT.INPUT_TEXT2' })}`}
            </div>
          </div>

          <div className="form-group">
            <TextField
              type="email"
              label="Email"
              margin="normal"
              fullWidth={true}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
          </div>

          <div className="kt-login__actions">
            <Grid container direction="row" justify="space-between" alignItems="stretch">
              <Button onClick={() => history.push('/auth')} variant="outlined" color="primary">
                {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
              </Button>

              <ButtonWithLoader
                disabled={recoveryPasswordLoading}
                loading={recoveryPasswordLoading}
              >
                {intl.formatMessage({ id: 'AUTH.LOGIN.FORGOT_PASSWORD' })}
              </ButtonWithLoader>
            </Grid>
          </div>
        </form>
      </div>
    </div>
  );
};

export default injectIntl(ForgotPassword);
