export const toFormData: (datas: any) => FormData = (datas) => {
  let formData = new FormData();
  for (var key in datas) {
    if (Array.isArray(datas[key])) {
      for (var i = 0; i < datas[key].length; i++) {
        formData.append(`${key}[]`, datas[key][i]);
      }
    } else {
      formData.append(key, datas[key]);
    }
  }
  return formData;
};

export const getResponseMessage = (e: any) =>
  (e && e.response && e.response.data && e.response.data.message) || 'Error';

export const formatPhone = (phone: string) =>
  `+${phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}`;

export const titlesDays = ['день', 'дня', 'дней'];

//https://gist.github.com/realmyst/1262561
//только для целых чисел
export const declOfNum = (isNumber: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  if (isNumber > -1) {
    return titles[
      isNumber % 100 > 4 && isNumber % 100 < 20 ? 2 : cases[isNumber % 10 < 5 ? isNumber % 10 : 5]
    ];
  } else {
    return '';
  }
};
