import axios from 'axios';
import { getBodiesUrl, getBodyByIdUrl, addBodyUrl, editBodyUrl } from '../constants';
import { IBodyItemToRequest } from '../pages/home/catalog/bodies/interfaces';

export function getBodies(page: number, perPage: number, generationId: number) {
  return axios.get(`${getBodiesUrl(generationId)}?page=${page}&per_page=${perPage}`);
}

export function getBodyById(id: number) {
  return axios.get(getBodyByIdUrl(id));
}

export function addBody(generationId: number, data: IBodyItemToRequest) {
  return axios.post(addBodyUrl(generationId), data);
}

export function editBody(id: number, data: IBodyItemToRequest) {
  return axios.put(editBodyUrl(id), data);
}
