import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  IAdvantageItem,
  IAdvantageItemToRequest,
} from '../../pages/home/properties/advantage/interfaces';
import { getAdvantage, editAdvantage } from '../../crud/advantage.crud';
import { getResponseMessage } from '../../utils/utils';

const FETCH_REQUEST = 'advantage/FETCH_REQUEST';
const FETCH_SUCCESS = 'advantage/FETCH_SUCCESS';
const FETCH_FAIL = 'advantage/FETCH_FAIL';

const CLEAR_EDIT = 'advantage/CLEAR_EDIT';
const EDIT_REQUEST = 'advantage/EDIT_REQUEST';
const EDIT_SUCCESS = 'advantage/EDIT_SUCCESS';
const EDIT_FAIL = 'advantage/EDIT_FAIL';

export interface IInitialState {
  advantage: IAdvantageItem | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: any;
}

const initialState: IInitialState = {
  advantage: undefined,
  loading: false,
  success: false,
  error: null,

  editLoading: false,
  editSuccess: false,
  editError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'advantage', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          advantage: undefined,
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          advantage: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_EDIT: {
        return { ...state, editLoading: false, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: () => createAction(FETCH_REQUEST),
  fetchSuccess: (payload: IServerResponse<IAdvantageItem>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: IAdvantageItemToRequest) => createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: any) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<IAdvantageItem> } = yield call(() => getAdvantage());
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: IAdvantageItemToRequest }) {
  try {
    const data = yield call(() => editAdvantage(payload));
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
}
