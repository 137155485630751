import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Topbar from '../../../_metronic/layout/header/LanguageTopbar.js';
import { useFetchLogin } from './hooks/useFetchLogin';
import { useLoginFormik } from './hooks/useLoginFormik';
import TermDialog from './components/TermDialog';
import PartnerForm from './components/PartnerForm';
import { useFetchLoginPhone } from './hooks/useFetchLoginPhone';
import { IAppState } from '../../store/rootDuck';
import { actions as authActions } from '../../store/ducks/auth.duck';
import { useTimer } from './hooks/useTimer';
import { useFetchCheckSms } from './hooks/useFetchCheckSms';
import LoginByPhone from './components/LoginByPhone';

const useStyles = makeStyles((theme) => ({
  languageBlock: {
    display: 'none',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
}));

const Login: React.FC<WrappedComponentProps> = ({ intl }) => {
  const classes = useStyles();
  const [isAgreementOpen, setOpenUserAgreement] = useState(false);
  const [login, loginLoading] = useFetchLogin();
  const [loginPhone, loginPhoneLoading] = useFetchLoginPhone();
  const [checkSms, checkLoading] = useFetchCheckSms();
  const [time, startTimer, resetTimer] = useTimer();
  const checkPhoneNumbers = useSelector(
    ({ auth: { checkPhoneNumbers } }: IAppState) => checkPhoneNumbers,
    shallowEqual
  );
  const dispatch = useDispatch();
  const formik = useLoginFormik(login, loginPhone, checkPhoneNumbers, checkSms);
  const { values, handleChange } = formik;

  useEffect(() => {
    return () => {
      resetTimer();
    };
  }, [resetTimer]);

  useEffect(() => {
    !checkPhoneNumbers && resetTimer();
    checkPhoneNumbers && startTimer();
  }, [checkPhoneNumbers]);

  const resending = useCallback(() => {
    loginPhone({ phone: values.phone });
    startTimer();
  }, [loginPhone, startTimer, values.phone]);
  return (
    <>
      <TermDialog isOpen={isAgreementOpen} handleClose={() => setOpenUserAgreement(false)} />
      <div className="kt-login__head">
        <div className="kt-login__signup">
          <span className="kt-login__signup-label">
            {intl.formatMessage({ id: 'AUTH.GENERAL.NO_ACCOUNT' })}
          </span>
          &nbsp;&nbsp;
          <Link
            to="/auth/registration"
            className="kt-link kt-login__signup-link"
            onClick={() => dispatch(authActions.clearLogin())}
          >
            {intl.formatMessage({ id: 'AUTH.GENERAL.SIGNUP_BUTTON' })}
          </Link>
        </div>
        <div className={classes.languageBlock}>
          <Topbar />
        </div>
      </div>

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          {/* select role */}
          {!checkPhoneNumbers && (
            <RadioGroup
              name="role"
              value={values.role}
              onChange={handleChange}
              style={{ marginTop: 40, marginBottom: -25 }}
            >
              <FormLabel component="legend">
                {intl.formatMessage({ id: 'AUTH.REGISTER.ROLE_TITLE' })}
              </FormLabel>
              <FormControlLabel
                value="ROLE_BUYER"
                control={<Radio />}
                label={intl.formatMessage({ id: 'USER.ROLES.BUYER' })}
              />
              <FormControlLabel
                value="ROLE_VENDOR"
                control={<Radio />}
                label={intl.formatMessage({ id: 'USER.ROLES.VENDOR' })}
              />
            </RadioGroup>
          )}

          <div style={{ display: values.role === 'ROLE_VENDOR' ? 'block' : 'none' }}>
            <PartnerForm
              loginLoading={loginLoading}
              formik={formik}
              openUserAgreement={() => setOpenUserAgreement(true)}
            />
          </div>
          <div style={{ display: values.role === 'ROLE_BUYER' ? 'block' : 'none' }}>
            <LoginByPhone
              formik={formik}
              openUserAgreement={() => setOpenUserAgreement(true)}
              checkPhoneNumbers={checkPhoneNumbers}
              time={time}
              resending={resending}
              loginPhoneLoading={loginPhoneLoading}
              checkLoading={checkLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(Login);
