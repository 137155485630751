import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { ActionWithPayload } from '../../../utils/action-helper';

// <--------------- Formik --------------->

export const useForgotPassFormik = (
  recoveryPasswordRequest: (payload: {
    email: string;
  }) => ActionWithPayload<
    'auth/RECOVERY_PASSWORD_REQUEST',
    {
      email: string;
    }
  >
) => {
  const intl = useIntl();
  return useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_INCORRECT' }))
        .required(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_REQUIRED' })),
    }),
    onSubmit: (values) => {
      recoveryPasswordRequest({ email: values.email });
    },
  });
};
