import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { phoneFormat } from '../utils/phoneFormat';
import { IUserForRegister, TRole } from '../interfaces';

// <--------------- Formik --------------->

export const useRegFormik = (
  register: (payload: IUserForRegister) => Promise<void>,
  loginPhone: (payload: { phone: string }) => Promise<void>,
  checkPhoneNumbers: boolean,
  fetchCheckSms: (payload: { phone: string; code: string }) => Promise<void>
) => {
  const intl = useIntl();
  return useFormik({
    initialValues: {
      email: '',
      login: '',
      role: 'ROLE_BUYER',
      acceptTerms: true,
      phone: '',
      smsCode: '',
    },
    validationSchema: () =>
      Yup.lazy((value: any) =>
        Yup.object().shape({
          email:
            value.role === 'ROLE_VENDOR'
              ? Yup.string()
                  .email(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_FIELD' }))
                  .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' }))
              : Yup.mixed(),
          phone:
            value.role === 'ROLE_VENDOR'
              ? Yup.mixed()
              : Yup.string()
                  .test(
                    'check if phone length valid',
                    intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' }),
                    (value) => phoneFormat(value)?.length === 10
                  )
                  .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
          smsCode: Yup.string().min(
            4,
            intl.formatMessage({ id: 'AUTH.VALIDATION.SMS_CODE.REQUIRED_FIELD' })
          ),
        })
      ),
    onSubmit: (values) => {
      if (checkPhoneNumbers) {
        fetchCheckSms({ phone: values.phone, code: values.smsCode });
      } else {
        values.role === 'ROLE_VENDOR'
          ? register({
              email: values.email,
              roles: [values.role as TRole],
              login: values.email,
            })
          : loginPhone({ phone: values.phone });
      }
    },
  });
};
