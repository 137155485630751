import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IModificationItemToRequest } from '../../pages/home/catalog/modifications/interfaces';
import {
  editModification,
  getModificationById,
  addModification,
} from '../../crud/modifications.crud';
import { IEquipmentItem } from '../../pages/home/catalog/equipments/interfaces';
import { getOptionsGroups } from '../../crud/optionsGroups.crud';
import { IOptionsGroupItem } from '../../pages/home/properties/options/interfaces';
import {getResponseMessage} from '../../utils/utils';

const FETCH_REQUEST = 'options/FETCH_REQUEST';
const FETCH_SUCCESS = 'options/FETCH_SUCCESS';
const FETCH_FAIL = 'options/FETCH_FAIL';

const CLEAR_FETCH_BY_ID = 'options/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'options/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'options/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'options/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'options/CLEAR_EDIT';
const ADD_REQUEST = 'options/ADD_REQUEST';
const EDIT_REQUEST = 'options/EDIT_REQUEST';
const EDIT_SUCCESS = 'options/EDIT_SUCCESS';
const EDIT_FAIL = 'options/EDIT_FAIL';

export interface IInitialState {
  options: IOptionsGroupItem[];
  loading: boolean;
  success: boolean;
  error: string | null;

  equipment: IEquipmentItem | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;
}

const initialState: IInitialState = {
  options: [],
  loading: false,
  success: false,
  error: null,

  equipment: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'options', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          options: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          options: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, options: [], error: action.payload, loading: false };
      }

      case CLEAR_EDIT: {
        return { ...state, editLoading: false, editError: null, editSuccess: false };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return {
          ...state,
          equipment: null,
          byIdLoading: false,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          equipment: null,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          equipment: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, equipment: null, byIdError: action.payload, byIdLoading: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: () => createAction(FETCH_REQUEST),
  fetchSuccess: (payload: IServerResponse<IOptionsGroupItem[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IEquipmentItem>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { bodyId: number; data: IModificationItemToRequest }) =>
    createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IModificationItemToRequest }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: any) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<IOptionsGroupItem[]> } = yield call(() =>
      getOptionsGroups()
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IEquipmentItem> } = yield call(() =>
      getModificationById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: { bodyId: number; data: IModificationItemToRequest } }) {
  try {
    const data = yield call(() => addModification(payload.bodyId, payload.data));
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IModificationItemToRequest } }) {
  try {
    const data = yield call(() => editModification(payload.id, payload.data));
    if (data.status === 200) {
      yield put(actions.editSuccess(data));
    } else {
      yield put(actions.editFail(data.errors));
    }
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
}
