import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import * as routerHelpers from '../../router/RouterHelpers';
import { IUser, IRegSuccessData } from '../../pages/auth/interfaces';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';

const REG_SUCCESS = 'auth/REG_SUCCESS';
const CLEAR_LOGIN = 'auth/CLEAR_LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGOUT = 'auth/LOGOUT';
const RECOVERY_PASSWORD_SUCCESS = 'auth/RECOVERY_PASSWORD_SUCCESS';
const SUCCESS_CHECK_PHONE = 'auth/SUCCESS_CHECK_PHONE';
const SET_TOKEN = 'auth/SET_TOKEN';
const SET_USER = 'auth/SET_USER';

export interface IInitialState {
  email: string | undefined;
  user: IUser | undefined;
  authToken: string | undefined;

  regLoading: boolean;
  regSuccess: boolean;

  loginLoading: boolean;
  loginSuccess: boolean;
  loginErrors: string | null;

  recoveryPasswordLoading: boolean;
  recoveryPasswordSuccess: boolean;
  recoveryPasswordErrors: string | null;

  checkPhoneNumbers: boolean;
}

const initialState: IInitialState = {
  email: undefined,
  user: undefined,
  authToken: undefined,

  regLoading: false,
  regSuccess: false,

  loginLoading: false,
  loginSuccess: false,
  loginErrors: null,

  recoveryPasswordLoading: false,
  recoveryPasswordSuccess: false,
  recoveryPasswordErrors: null,

  checkPhoneNumbers: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'auth', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_LOGIN: {
        return {
          ...state,
          loginLoading: false,
          loginErrors: null,
          loginSuccess: false,
          checkPhoneNumbers: false,
        };
      }

      case REG_SUCCESS: {
        return {
          ...state,
          emailRequested: action.payload.data.email,
          regLoading: false,
          regSuccess: true,
        };
      }

      case LOGIN_SUCCESS: {
        return {
          ...state,
          loginLoading: false,
          loginSuccess: true,
          user: action.payload.data,
          authToken: action.payload.data.api_token,
          loginErrors: null,
        };
      }

      case LOGOUT: {
        routerHelpers.forgotLastLocation();
        return initialState;
      }

      case RECOVERY_PASSWORD_SUCCESS: {
        return {
          ...state,
          recoveryPasswordLoading: false,
          recoveryPasswordSuccess: true,
          recoveryPasswordErrors: null,
          email: action.payload.data.email,
        };
      }

      case SUCCESS_CHECK_PHONE: {
        return {
          ...state,
          checkPhoneNumbers: true,
        };
      }

      case SET_TOKEN: {
        return {
          ...state,
          authToken: action.payload,
        };
      }

      case SET_USER: {
        return {
          ...state,
          user: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  regSuccess: (payload: IServerResponse<IRegSuccessData>) => createAction(REG_SUCCESS, payload),
  clearLogin: () => createAction(CLEAR_LOGIN),
  loginSuccess: (payload: IServerResponse<IUser>) => createAction(LOGIN_SUCCESS, payload),
  logout: () => createAction(LOGOUT),
  recoveryPasswordSuccess: (payload: IServerResponse<IUser>) =>
    createAction(RECOVERY_PASSWORD_SUCCESS, payload),
  checkPhoneSuccess: () => createAction(SUCCESS_CHECK_PHONE),
  setToken: (payload: string) => createAction(SET_TOKEN, payload),
  setUser: (payload: any) => createAction(SET_USER, payload),
};

export type TActions = ActionsUnion<typeof actions>;
