import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { IStore } from '../../interfaces/store';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';

const FETCH_SUCCESS = 'stores/FETCH_SUCCESS';
const SET_EMPTY = 'stores/SET_EMPTY';
const CLEAR_STORES = 'stores/CLEAR_STORES';
const FETCH_BY_ID_SUCCESS = 'stores/FETCH_BY_ID_SUCCESS';
const CLEAR_EDIT = 'stores/CLEAR_EDIT';
const EDIT_SUCCESS = 'stores/EDIT_SUCCESS';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  stores: IStore[] | undefined;
  loading: boolean;
  success: boolean;

  store: IStore | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  stores: undefined,
  loading: false,
  success: false,

  store: undefined,
  byIdLoading: false,
  byIdSuccess: false,

  editLoading: false,
  editSuccess: false,
  editError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'stores', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, stores: [] };
      }

      case CLEAR_STORES: {
        return { ...state, stores: undefined };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          stores: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          store: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          store: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
        };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, store: action.payload.data };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchSuccess: (payload: IServerResponse<IStore[]>) => createAction(FETCH_SUCCESS, payload),
  setEmpty: () => createAction(SET_EMPTY),
  clearStores: () => createAction(CLEAR_STORES),

  fetchByIdSuccess: (payload: IServerResponse<IStore>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editSuccess: (payload: IServerResponse<IStore>) => createAction(EDIT_SUCCESS, payload),
};

export type TActions = ActionsUnion<typeof actions>;
