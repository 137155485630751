import axios from 'axios';

import {
  getGenerationsUrl,
  addGenerationUrl,
  editGenerationUrl,
  getGenerationByIdUrl,
} from '../constants';
import { IGenerationItemToRequest } from '../pages/home/catalog/generations/interfaces';

export function getGeneration(page: number, perPage: number, modelId: number) {
  return axios.get(`${getGenerationsUrl(modelId)}?page=${page}&per_page=${perPage}`);
}

export function getGenerationById(generationId: number) {
  return axios.get(getGenerationByIdUrl(generationId));
}

export function addGeneration(modelId: number, data: IGenerationItemToRequest) {
  return axios.post(addGenerationUrl(modelId), data);
}

export function editGeneration(generationId: number, data: IGenerationItemToRequest) {
  return axios.put(editGenerationUrl(generationId), data);
}
