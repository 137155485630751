import { IOptionsGroupItem } from '../../../properties/options/interfaces';

export default (options: IOptionsGroupItem[]): number[] => {
  let count: number[] = [];
  options.forEach(item => {
    item.sorted_equipments.forEach(item => {
      count.push(item.id);
    });
  });
  return count;
};
