import axios from 'axios';
import { getPropertiesUrl, addPropertyUrl, editPropertyUrl } from '../constants';
import {
  TVehicleProperty,
  IPropertyItemToAdd,
  IPropertyItemToEdit,
} from '../pages/home/properties/interfaces';

export function getProperties(type: TVehicleProperty) {
  return axios.get(getPropertiesUrl(type));
}

export function addProperty(type: TVehicleProperty, data: IPropertyItemToAdd) {
  return axios.post(addPropertyUrl(type), data);
}

export function editProperty(id: number, data: IPropertyItemToEdit) {
  return axios.put(editPropertyUrl(id), data);
}
