import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import ButtonWithLoader from '../../../components/ui/Buttons/ButtonWithLoader';
import { Link } from 'react-router-dom';

interface IProps {
  formik: any;
  loginLoading: boolean;
  openUserAgreement: () => void;
}

const PartnerForm: React.FC<IProps & WrappedComponentProps> = ({
  intl,
  formik,
  loginLoading,
  openUserAgreement,
}) => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <form noValidate autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
      <div className="form-group mb-0">
        <TextField
          type="email"
          label={intl.formatMessage({
            id: 'AUTH.INPUT.LOGIN.OR.EMAIL',
          })}
          margin="normal"
          className="kt-width-full"
          name="login"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.login}
          helperText={touched.login && errors.login}
          error={Boolean(touched.login && errors.login)}
        />
      </div>

      <div className="form-group mb-0">
        <TextField
          autoComplete="off"
          type="password"
          margin="normal"
          label={intl.formatMessage({
            id: 'AUTH.INPUT.PASSWORD',
          })}
          className="kt-width-full"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          helperText={touched.password && errors.password}
          error={Boolean(touched.password && errors.password)}
        />
      </div>
      <div className="form-group mb-0">
        <FormControlLabel
          label={
            <>
              {intl.formatMessage({ id: 'AUTH.REGISTER.AGREE_TERM' })}{' '}
              <div className="kt-link" onClick={openUserAgreement}>
                {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
              </div>
            </>
          }
          control={
            <Checkbox
              color="primary"
              name="acceptTerms"
              onBlur={handleBlur}
              onChange={handleChange}
              checked={values.acceptTerms}
            />
          }
        />
      </div>

      <div className="kt-login__actions">
        <Link to="/auth/forgot-password">
          {intl.formatMessage({ id: 'AUTH.LOGIN.FORGOT_PASSWORD' })}
        </Link>
        <ButtonWithLoader disabled={loginLoading || !values.acceptTerms} loading={loginLoading}>
          <FormattedMessage id="AUTH.LOGIN.BUTTON" />
        </ButtonWithLoader>
      </div>
    </form>
  );
};

export default injectIntl(PartnerForm);
