import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {IProduct, IProductBanners, IReview} from '../../interfaces/product';

const FETCH_SUCCESS = 'products/FETCH_SUCCESS';
const SET_EMPTY = 'products/SET_EMPTY';
const FETCH_BY_ID_SUCCESS = 'products/FETCH_BY_ID_SUCCESS';
const CLEAR_PRODUCTS = 'products/CLEAR_PRODUCTS';
const CLEAR_PRODUCT = 'products/CLEAR_PRODUCT';
const UPLOAD_PHOTO_REQUEST = 'products/UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'products/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAIL = 'products/UPLOAD_PHOTO_FAIL';
const DEL_PHOTO_SUCCESS = 'products/DEL_PHOTO_SUCCESS';
const CLEAR_SET_MAIN_PHOTO = 'products/CLEAR_SET_MAIN_PHOTO';
const SET_MAIN_PHOTO_SUCCESS = 'products/SET_MAIN_PHOTO_SUCCESS';
const SET_PRODUCT_REVIEWS = 'products/SET_PRODUCT_REVIEWS';
const SET_PRODUCT_BANNERS = 'products/SET_PRODUCT_BANNERS';
const CLEAR_COMPANY = 'products/CLEAR_COMPANY';
const CLEAR_PAGE = 'products/CLEAR_PAGE';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  products: IProduct[] | undefined;
  productsBanners: IProductBanners[] | undefined;
  loading: boolean;
  success: boolean;

  product: IProduct | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;

  photoLoading: boolean;
  photoSuccess: boolean;
  photoError: string | null;

  delPhotoLoading: boolean;
  delPhotoSuccess: boolean;

  setMainPhotoLoading: boolean;
  setMainPhotoSuccess: boolean;
  setMainPhotoError: string | null;
  reviewsProduct: {
    total: number;
    rating: number | null;
    reviews: IReview[];
  } | null;
}

const defaultPaginatorProps = {
  page: 1,
  per_page: 20,
  total: 0,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  products: undefined,
  productsBanners: undefined,
  loading: false,
  success: false,

  product: undefined,
  byIdLoading: false,
  byIdSuccess: false,

  photoLoading: false,
  photoSuccess: false,
  photoError: null,

  delPhotoLoading: false,
  delPhotoSuccess: false,

  setMainPhotoLoading: false,
  setMainPhotoSuccess: false,
  setMainPhotoError: null,
  reviewsProduct: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'products', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, products: [] };
      }

      case CLEAR_PRODUCTS: {
        return { ...state, products: [] };
      }

      case CLEAR_PAGE: {
        return { ...state, ...defaultPaginatorProps };
      }

      case CLEAR_PRODUCT: {
        return { ...state, product: undefined };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          products: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case UPLOAD_PHOTO_REQUEST: {
        return {
          ...state,
          photoLoading: true,
          photoSuccess: false,
          photoError: null,
        };
      }

      case UPLOAD_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          photoLoading: false,
          photoSuccess: true,
        };
      }

      case UPLOAD_PHOTO_FAIL: {
        return { ...state, photoLoading: false, photoError: action.payload };
      }

      case DEL_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          delPhotoLoading: false,
          delPhotoSuccess: true,
        };
      }

      case CLEAR_SET_MAIN_PHOTO: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: true,
        };
      }

      case SET_PRODUCT_REVIEWS: {
        return {
          ...state,
          reviewsProduct: {
            rating: action.payload.rating,
            reviews: action.payload.data,
            total: action.payload.total,
          },
        };
      }

      case SET_PRODUCT_BANNERS: {
        return {
          ...state,
          productsBanners: action.payload,
        };
      }

      case CLEAR_COMPANY: {
        return {
          ...state,
          product: undefined,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchSuccess: (payload: IServerResponse<IProduct[]>) => createAction(FETCH_SUCCESS, payload),
  setEmpty: () => createAction(SET_EMPTY),
  clearProducts: () => createAction(CLEAR_PRODUCTS),
  clearPage: () => createAction(CLEAR_PAGE),

  fetchByIdSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),

  clearProduct: () => createAction(CLEAR_PRODUCT),

  uploadPhotoRequest: (payload: { modificationId: number; files: FormData }) =>
    createAction(UPLOAD_PHOTO_REQUEST, payload),
  uploadPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(UPLOAD_PHOTO_SUCCESS, payload),
  uploadPhotoFail: (payload: string) => createAction(UPLOAD_PHOTO_FAIL, payload),

  delPhotoSuccess: (payload: IServerResponse<IProduct>) => createAction(DEL_PHOTO_SUCCESS, payload),

  clearSetMainPhotoRequest: () => createAction(CLEAR_SET_MAIN_PHOTO),
  setMainPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(SET_MAIN_PHOTO_SUCCESS, payload),
  setProductReviews: (payload: { rating: number | null; data: IReview[]; total: number }) =>
    createAction(SET_PRODUCT_REVIEWS, payload),
  setProductBanners: (payload: IProductBanners[]) =>
    createAction(SET_PRODUCT_BANNERS, payload),
  clearCompany: () => createAction(CLEAR_COMPANY),
};

export type TActions = ActionsUnion<typeof actions>;
