import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_metronic';
import { IAppState } from '../../store/rootDuck';
import { actions as authActions } from '../../store/ducks/auth.duck';

const Logout: React.FC = () => {
  const hasAuthToken = useSelector(
    ({ auth: { authToken } }: IAppState) => Boolean(authToken),
    shallowEqual
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
};

export default Logout;
