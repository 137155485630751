import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ICategoryItem } from '../../pages/home/categories/interfaces';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IProduct } from '../../interfaces/product';

const FETCH_ROOT_SUCCESS = 'categories/FETCH_ROOT_SUCCESS';

const FETCH_CHILDREN_SUCCESS = 'categories/FETCH_CHILDREN_SUCCESS';

const FETCH_PARENT_SUCCESS = 'categories/FETCH_PARENT_SUCCESS';

const FETCH_FULL_SUCCESS = 'categories/FETCH_FULL_SUCCESS';

const FETCH_BY_ID_SUCCESS = 'categories/FETCH_BY_ID_SUCCESS';

const SET_POSITIONS_SUCCESS = 'categories/SET_POSITIONS_SUCCESS';

const CLEAR_EDIT = 'categories/CLEAR_EDIT';

const DEL_PHOTO_SUCCESS = 'categories/DEL_PHOTO_SUCCESS';

const SET_MAIN_PHOTO_SUCCESS = 'categories/SET_MAIN_PHOTO_SUCCESS';
const CLEAR_PARENT = 'categories/CLEAR_PARENT';
const CLEAR_CATEGORY = 'categories/CLEAR_CATEGORY';
const CLEAR_CATEGORIES = 'categories/CLEAR_CATEGORIES';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  categories: ICategoryItem[] | undefined;
  loading: boolean;
  success: boolean;

  category: ICategoryItem | undefined;

  byIdLoading: boolean;
  byIdSuccess: boolean;

  parent: ICategoryItem | undefined;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delPhotoLoading: boolean;
  delPhotoSuccess: boolean;

  setMainPhotoLoading: boolean;
  setMainPhotoSuccess: boolean;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  categories: undefined,
  loading: false,
  success: false,

  category: undefined,
  byIdLoading: false,
  byIdSuccess: false,

  parent: undefined,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delPhotoLoading: false,
  delPhotoSuccess: false,

  setMainPhotoLoading: false,
  setMainPhotoSuccess: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'categories', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ROOT_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          categories: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FULL_SUCCESS: {
        return {
          ...state,
          categories: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_CHILDREN_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          categories: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_PARENT_SUCCESS: {
        return {
          ...state,
          parent: action.payload.data,
          loading: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          category: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case SET_POSITIONS_SUCCESS: {
        return {
          ...state,
          categories: action.payload,
          loading: false,
        };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          model: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
        };
      }

      case DEL_PHOTO_SUCCESS: {
        return {
          ...state,
          category: action.payload.data,
          delPhotoLoading: false,
          delPhotoSuccess: true,
        };
      }

      case SET_MAIN_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: true,
        };
      }

      case CLEAR_PARENT: {
        return {
          ...state,
          parent: undefined,
        };
      }

      case CLEAR_CATEGORY: {
        return {
          ...state,
          category: undefined,
        };
      }

      case CLEAR_CATEGORIES: {
        return {
          ...state,
          categories: undefined,
          category: undefined,
          parent: undefined,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRootSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_ROOT_SUCCESS, payload),
  fetchChildrenSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_CHILDREN_SUCCESS, payload),
  fetchParentSuccess: (payload: IServerResponse<ICategoryItem>) =>
    createAction(FETCH_PARENT_SUCCESS, payload),
  fetchFullSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_FULL_SUCCESS, payload),

  fetchByIdSuccess: (payload: IServerResponse<ICategoryItem>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),

  setPositionsSuccess: (payload: ICategoryItem[]) => createAction(SET_POSITIONS_SUCCESS, payload),

  clearEdit: () => createAction(CLEAR_EDIT),

  delPhotoSuccess: (payload: IServerResponse<ICategoryItem>) =>
    createAction(DEL_PHOTO_SUCCESS, payload),

  setMainPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(SET_MAIN_PHOTO_SUCCESS, payload),
  clearParent: () => createAction(CLEAR_PARENT),
  clearCategory: () => createAction(CLEAR_CATEGORY),
  clearCategories: () => createAction(CLEAR_CATEGORIES),
};

export type TActions = ActionsUnion<typeof actions>;
