import axios from 'axios';
import { GET_ACTIVE_BRANDS_URL, GET_BRANDS_URL, GET_BRAND_URL } from '../constants';
import { IBrandItem } from '../pages/home/catalog/brands/interfaces';
import { IPaginationProps } from '../interfaces/server';
import { IRouterParams } from '../interfaces/router';

export function getBrands({
  page = 1,
  per_page = 20,
  active = false,
}: IPaginationProps & IRouterParams) {
  return axios.get(
    `${active ? GET_ACTIVE_BRANDS_URL : GET_BRANDS_URL}?page=${page}&per_page=${per_page}`
  );
}

export function getBrandById(id: number) {
  return axios.get(`${GET_BRAND_URL}/${id}`);
}

export function createBrand(data: IBrandItem) {
  return axios.post(GET_BRAND_URL, data);
}

export function editBrand(id: number, data: IBrandItem) {
  return axios.put(`${GET_BRAND_URL}/${id}`, data);
}
