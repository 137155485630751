import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ButtonWithLoader from '../../../components/ui/Buttons/ButtonWithLoader';
import { actions as authActions } from '../../../store/ducks/auth.duck';

interface IProps {
  formik: any;
  openUserAgreement: () => void;
  checkPhoneNumbers: boolean;
  time: any;
  resending: () => void;
  loginPhoneLoading: boolean;
  checkLoading: boolean;
  isReg?: boolean;
}

const LoginByPhone: React.FC<IProps & WrappedComponentProps> = ({
  intl,
  formik,
  checkLoading,
  loginPhoneLoading,
  time,
  checkPhoneNumbers,
  openUserAgreement,
  resending,
  isReg,
}) => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;
  const dispatch = useDispatch();
  return (
    <form
      noValidate
      autoComplete="off"
      className={isReg ? undefined : 'kt-form'}
      onSubmit={handleSubmit}
    >
      <div className="form-group mb-0">
        {checkPhoneNumbers ? (
          <TextField
            type="sms"
            label={intl.formatMessage({
              id: 'AUTH.INPUT.SMS_CODE',
            })}
            margin="normal"
            className="kt-width-full"
            name="smsCode"
            onBlur={handleBlur}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (
                (e.target.value === '' || re.test(e.target.value)) &&
                (values.smsCode.length < 4 || values.smsCode.length > e.target.value.length)
              ) {
                handleChange(e);
              }
            }}
            value={values.smsCode}
            helperText={touched.smsCode && errors.smsCode}
            error={Boolean(touched.smsCode && errors.smsCode)}
            inputProps={{ inputMode: 'numeric' }}
          />
        ) : (
          <InputMask
            mask={`+7 999 999 99 99`}
            onBlur={handleBlur}
            value={values.phone}
            onChange={(e: any) => {
              setFieldValue('phone', e.target.value || '');
            }}
          >
            {(inputProps: any) => (
              <TextField
                {...inputProps}
                type="tel"
                label={intl.formatMessage({
                  id: 'AUTH.INPUT.PHONE',
                })}
                margin="normal"
                className="kt-width-full"
                name="phone"
                helperText={touched.phone && errors.phone}
                error={Boolean(touched.phone && errors.phone)}
                InputLabelProps={{
                  shrink: !!values.phone,
                }}
              />
            )}
          </InputMask>
        )}
      </div>
      <div className="form-group mb-0">
        {!checkPhoneNumbers && (
          <FormControlLabel
            label={
              <>
                {intl.formatMessage({ id: 'AUTH.REGISTER.AGREE_TERM' })}{' '}
                <div className="kt-link" onClick={openUserAgreement}>
                  {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
                </div>
              </>
            }
            control={
              <Checkbox
                color="primary"
                name="acceptTerms"
                onBlur={handleBlur}
                onChange={handleChange}
                checked={values.acceptTerms}
              />
            }
          />
        )}
        {checkPhoneNumbers && time > 0 && (
          <>
            <span>
              {`${intl.formatMessage({
                id: 'AUTH.REGISTER.REPLAY_SMS',
              })}`}
            </span>

            <div className="kt-link">{time}</div>
          </>
        )}
        {checkPhoneNumbers && time <= 0 && (
          <div className="kt-link" style={{ cursor: 'pointer' }} onClick={resending}>
            {intl.formatMessage({ id: 'AUTH.REGISTER.BUTTON_REPLAY' })}
          </div>
        )}
      </div>

      <div className="kt-login__actions">
        {checkPhoneNumbers && (
          <ButtonWithLoader onPress={() => dispatch(authActions.clearLogin())}>
            <FormattedMessage id="AUTH.BUTTON.BACK" />
          </ButtonWithLoader>
        )}
        {!checkPhoneNumbers && isReg && (
          <Link to="/auth">
            <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
              {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
            </button>
          </Link>
        )}
        <ButtonWithLoader
          disabled={loginPhoneLoading || !values.acceptTerms || checkLoading}
          loading={loginPhoneLoading || checkLoading}
        >
          {checkPhoneNumbers ? (
            <FormattedMessage id="AUTH.LOGIN.BUTTON" />
          ) : (
            <FormattedMessage id="AUTH.LOGIN.BUTTON_NEXT" />
          )}
        </ButtonWithLoader>
      </div>
    </form>
  );
};

export default injectIntl(LoginByPhone);
