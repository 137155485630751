import axios from 'axios';
import { IOrderForEdit } from '../interfaces/order';
import { getOrdersByStatus, getOrderurl } from '../constants';

export const getOrders = (statuses: string, page: number, per_page: number) => {
  return axios.get(`${getOrdersByStatus(statuses)}&page=${page}&per_page=${per_page}`);
};

export const deleteOrder = (id: number) => {
  return axios.delete(getOrderurl(id));
};

export const editOrder = (id: number, order: IOrderForEdit) => axios.put(getOrderurl(id), order);

export const getOrderById = (id: number) => axios.get(getOrderurl(id));
